import { get, post, patch, put, delete as del } from "axios";
import { authPayload, authPostPayload } from "../utils/requestsPayloads";

// Railway api
export const api_url = 'https://staffing-api-testing.up.railway.app/'
export const cable_url = 'wss://staffing-api-testing.up.railway.app/cable'

// Localy Running server API
// export const api_url = "http://localhost:8000/";
// export const cable_url = "ws://localhost:8000/cable";

export function searchUrl(props) {
  // Get url for search
  // PROPS FORMAT: [id_option, id_category, id_industry, id_client, tags, project_code, subIndustry_id, type_id, clientIndustry_id]

  if (props[4].length === 0) {
    props[4] = null;
  }
  for (let i = 0; i < props.length; i++) {
    if (props[i] === "" || props[i] === undefined) {
      props[i] = null;
    }
  }
  return `${api_url}search/${props[0]}/${props[1]}/${props[2]}/${props[3]}/${props[4]}/${props[5]}/${props[6]}/${props[7]}/${props[8]}/`;
}

// Staffing API URLS
export const ASSIGNATIONS_INFO_URL = `${api_url}assignations`;
export const VACATIONS_POST = `${api_url}vacations/buk_update`;
export const STAFFINGURL = `${api_url}obtainValues`;
export const PROJECT_ACTIVE_ASSIGNATIONS_URL = `${api_url}projects/assignations`;
export const CLOSE_ACTIVE_ASSIGNATIONS_URL = `${api_url}projects/end_active_assignations`;
export const PROJECT_EXPERIENCE_URL = `${api_url}projects/experiences`;
export const OBTAIN_VALUES_URL = `${api_url}obtainValues`;

// KM URLS
// GET
export const CATEGORIES_URL = `${api_url}categories`;
const DOC_CATEGORIES_URL = `${api_url}document_categories`;
export const DOCUMENTS_URL = `${api_url}documents`;
export const INDUSTRIES_URL = `${api_url}industries`;
export const SUB_INDUSTRIES_URL = `${api_url}sub_industries`;
export const TYPES_URL = `${api_url}types`;
export const SUB_TYPES_URL = `${api_url}sub_types`;
export const PROJECTS_TAGS_URL = `${api_url}tags`;
const CONTACTS_URL = `${api_url}contacts`; // no es necesario ponerlo para cada metodo, es la misma ruta

// POST
export const POST_INDUSTRY_URL = `${api_url}industries`;
export const POST_SUB_INDUSTRY_URL = `${api_url}sub_industries`;
export const POST_CATEGORY_URL = `${api_url}categories`;
const POST_DOCUMENT_URL = `${api_url}documents`;
export const POST_TYPE_URL = `${api_url}types`;
export const POST_SUB_TYPE_URL = `${api_url}sub_types`;
const POST_CLIENT_URL = `${api_url}clients`;
const POST_PROJECT_URL = `${api_url}projects`;
const POST_TAGS_URL = `${api_url}tags`;

// DELETE
export const DELETE_CLIENT_URL = `${api_url}clients`;
export const DELETE_PROJECT_URL = `${api_url}projects`;
const DELETE_DOCUMENT_URL = `${api_url}documents`;
const DELETE_TAG_URL = `${api_url}tags`;

// PATCH
const PATCH_CLIENT_URL = `${api_url}clients`;
const PATCH_DOCUMENT_URL = `${api_url}documents`;
const PATCH_PROJECT_URL = `${api_url}projects`;
const PATCH_CHECKLIST_INICIO_URL = `${api_url}projects/checklistInicio`;
const PATCH_CHECKLIST_CIERRE_URL = `${api_url}projects/checklistCierre`;

// end KM URLS

export const FIRST_LEVEL_INDUSTRIES_URL = `${api_url}first_level_industries.json`;
export const SECOND_LEVEL_INDUSTRIES_URL = `${api_url}second_level_industries.json`;
export const CLIENTS_URL = `${api_url}clients`;

export const PROJECTS_URL = `${api_url}projects.json`;
export const PROPOSAL_URL = `${api_url}proposals.json`;

export const PROPOSAL_ASIGNATIONS_URL = `${api_url}proposal_asignations.json`;

// export const DOCUMENTS_URL = `${api_url}documents.json`

export const INFORMARTION_SOURCES_URL = `${api_url}information_sources.json`;

export const APQC_TOOLS_URL = `${api_url}apqc_tools.json`;
export const APQC_TOOLS_TAGS_URL = `${api_url}apqc_tags.json`;

export const INFORMATION_SOURCE_TAGS_URL = `${api_url}information_source_tags.json`;

export const SINGLE_DOCUMENTS_URL = `${api_url}single_documents.json`;

export const DOCUMENT_TAGS_URL = `${api_url}document_tags.json`;

export const SINGLE_DOCUMENT_TAGS_URL = `${api_url}single_document_tags.json`;

export const PROJECT_ASIGNATIONS_URL = `${api_url}project_asignations.json`;

export const FIRST_PROJECT_TYPES_URL = `${api_url}first_project_types.json`;
export const SECOND_PROJECT_TYPES_URL = `${api_url}second_project_types.json`;

export const CONSULTANTS_URL = `${api_url}consultants.json`;

export const TAGS_URL = `${api_url}tags.json`;

export const VACATIONS_URL = `${api_url}vacations.json`;

export const USER_TOKEN_URL = `${api_url}user_token`;
export const USERS_URL = `${api_url}users`;

export const CURRENT_URL = `${api_url}users/current`;
export const AUTH_URL = `${api_url}auth`;
export const FORGOT_PASSWORD_URL = `${api_url}users/forgot_password`;

// PERFORMANCE EVALUATION URLS
export const COMPETENCIES_URL = `${api_url}competencies/`;
export const PROCESSES_URL = `${api_url}evalprocesses/`;
export const QUARTERS_URL = `${api_url}quarters/`;
export const THREE_QUARTERS_URL = `${api_url}quarters/last_three`;
export const EVALUATIONS_URL = `${api_url}evaluations/`;
export const SCORES_URL = `${api_url}scores/`;
export const ENGAGEMENT_URL = `${api_url}engagement/`;
export const CONSULTANTS_INFO_URL = `${api_url}consultants_info`;
export const SEARCH_EVALUATION_URL = `${api_url}search_evaluations/`;
export const REQUESTACCESS_URL = `${api_url}access_requests/`;
export const NOTIFICATIONS_URL = `${api_url}notifications/`;
export const REQUEST_URL = `${api_url}requests/`;
export const SETEVALUATIONSTATUS = `${api_url}evaluations/`;
// Performance functions
// -------------------------------------------------------------
export function getCompetency(cid) {
  // Get one competency
  return get(`${COMPETENCIES_URL}${cid}`, authPayload()).then((res) => {
    return res.data;
  });
}

export function getNotifications() {
  // Get all notifications
  return get(NOTIFICATIONS_URL, authPayload()).then((res) => {
    return res.data;
  });
}

export function deleteNotification(id) {
  // Delete notification
  return del(`${NOTIFICATIONS_URL}${id}`, authPayload()).then((res) => {
    return res.data;
  });
}

export function markNotificationAsRead(id){
  // Mark notification as read
  return put(`${NOTIFICATIONS_URL}${id}/mark_as_read`, {}, authPayload()).then((res) => {
    return res.data;
  });
}

export function markNotificationAsUnread(id){
  // Mark notification as unread
  return put(`${NOTIFICATIONS_URL}${id}/mark_as_unread`, {}, authPayload()).then((res) => {
    return res.data;
  });
}

export function markAllAsRead(){
  // Mark all notifications as read
  return put(`${NOTIFICATIONS_URL}mark_all_as_read`, {}, authPayload()).then((res) => {
    return res.data;
  });
}

export function destroyAllNotifications(){
  // Destroy all notifications
  return del(`${NOTIFICATIONS_URL}delete_all`, authPayload()).then((res) => {
    return res.data;
  });
}

export function getQuarters(){
  // Get all quarters
  return get(QUARTERS_URL, authPayload()).then((res) => {
    return res.data;
  });
}

export function getQuarter(id){
  // Get one quarter
  return get(`${QUARTERS_URL}${id}`, authPayload()).then((res) => {
    return res.data;
  });
}

export function createCompetency(values) {
  // Create new competency
  return post(COMPETENCIES_URL, values, authPayload()).then((res) => {
    return res.data;
  });
}

export function updateCompetency(cid, values) {
  // Update competency
  return patch(`${COMPETENCIES_URL}${cid}`, values, authPayload()).then(
    (res) => {
      return res.data;
    }
  );
}

export function getSearchEvaluations(values) {
  // Get evaluations
  return get(SEARCH_EVALUATION_URL, { params: values, ...authPayload() }).then(
    (res) => {
      return res.data;
    }
  );
}

//Funciones de requests generalizadas

export function getRequests(){
  // Get all requests
  return get(REQUEST_URL, authPayload()).then((res) => {
    return res.data;
  });
}

export function sendRequest(requestable_id, requestable_type, request_type){
  // Send request
  return post(`${REQUEST_URL}`, {requestable_id: requestable_id, requestable_type: requestable_type, request_type: request_type}, authPayload()).then((res) => {
    return res.data;
  });
}

export function deleteRequest(id){
  // Delete request
  return del(`${REQUEST_URL}${id}`, authPayload()).then((res) => {
    return res.data;
  });
}

export function acceptRequest(request_id, requestable_id, request_type, requestable_type ){
  // Accept request
  return patch(`${REQUEST_URL}${request_id}/accept`, {requestable_id: requestable_id, requestable_type: requestable_type, request_type: request_type }, authPayload()).then((res) => {
    return res.data;
  });
}

export function denyRequest(request_id, requestable_id, request_type, requestable_type){
  // Accept request
  return patch(`${REQUEST_URL}${request_id}/deny`, {requestable_id: requestable_id, requestable_type: requestable_type, request_type: request_type }, authPayload()).then((res) => {
    return res.data;
  });
}

//Fin de funciones de requests generalizadas

export const GET_EXCEL_URL = `${api_url}reports/get_excel`;

// Get functions staffing
// -------------------------------------------------------------
export function fetchAssignations() {
  // Get all assignations
  return get(ASSIGNATIONS_INFO_URL, authPayload()).then((res) => {
    return res.data;
  });
}

export function fetchVacations() {
  // Get all vacations
  return get(`${api_url}vacations`, authPayload()).then((res) => {
    return res.data;
  });
}

export function getAssignationsByWeek() {
  return get(`${api_url}consultants_ocupation`, authPayload()).then((res) => {
    return res.data;
  });
}

export function getAssignationsByConsultant(consultant_id) {
  return get(
    `${api_url}assignations/consultant/${consultant_id}`,
    authPayload()
  ).then((res) => {
    return res.data;
  });
}

export function projectActiveAssignations(value) {
  const url = `${PROJECT_ACTIVE_ASSIGNATIONS_URL}/${value}`;
  return get(url, authPayload()).then((res) => {
    console.log("res.data: ", res.data);
    return res.data;
  });
}

export function closeActiveAssignations(value) {
  const url = `${CLOSE_ACTIVE_ASSIGNATIONS_URL}/${value.project_id}`;
  const config = {
    id: value.project_id,
  };
  return post(url, config, authPayload()).then((res) => {
    return res.data;
  });
}

export function obtainValuesExcel(start_date) {
  const values = {
    start_date: start_date,
  };
  return post(OBTAIN_VALUES_URL, values, authPayload()).then((res) => {
    console.log("res.data: ", res.data);
    return res.data;
  });
}
export function projectExperience(values) {
  // values debe ser un array con los ids de los consultores
  const data = { consultant_ids: values };
  return post(`${PROJECT_EXPERIENCE_URL}`, data, authPostPayload()).then(
    (res) => {
      return res.data;
    }
  );
}
// -------------------------------------------------------------

// Patch functions staffing
// -------------------------------------------------------------
export function patchAssignation(values) {
  // Patch (update) old assignation
  return patch(
    `${ASSIGNATIONS_INFO_URL}/${values.id}`,
    values,
    authPayload()
  ).then((res) => {});
}

// Post functions staffing
// -------------------------------------------------------------
export function fetchPostAssignation(values) {
  console.log("values: ", values);
  // Post new assignation
  return post(ASSIGNATIONS_INFO_URL, values, authPayload()).then((res) => {});
}

export function fetchPostVacation(values) {
  // Post new vacations
  return post(VACATIONS_POST, values, authPayload()).then((res) => {
    console.log(res.data);
  });
}

// -------------------------------------------------------------

// Delete functions staffing
// -------------------------------------------------------------
export function deleteAssignation(values) {
  // Delete assignation
  return del(`${ASSIGNATIONS_INFO_URL}/${values}`, authPayload()).then(
    (res) => {}
  );
}
// -------------------------------------------------------------

// GET functions KM
// -------------------------------------------------------------
export function fetchCategories() {
  // Get all industries for select
  return get(CATEGORIES_URL, authPayload()).then((res) => {
    return res.data;
  });
}
export function fetchCategory(category_id) {
  // Get one category
  return get(CATEGORIES_URL, authPayload()).then((res) => {
    return res.data.find(({ id }) => id === category_id);
  });
}
export function fetchClients() {
  // Get all clients for select
  return get(CLIENTS_URL, authPayload()).then((res) => {
    return res.data;
  });
}
export function fetchClient(client_id) {
  // Get one clients
  return get(CLIENTS_URL, authPayload()).then((res) => {
    return res.data.find(({ id }) => id === client_id);
  });
}
export function fetchConsultants() {
  // Get active consultants for select
  return get(CONSULTANTS_INFO_URL, authPayload()).then((res) => {
    // const activeConsultants = res.data.filter((c) => c.is_active === true);
    // return activeConsultants;
    return res.data;
  });
}
export function fetchConsultant(consultant_id) {
  // Get one consultant
  return get(CONSULTANTS_INFO_URL, authPayload()).then((res) => {
    return res.data.find(({ id }) => id === consultant_id);
  });
}
export function fetchContacts() {
  // Get all contacts
  return get(CONTACTS_URL, authPayload()).then((res) => {
    return res.data;
  });
}
export function fetchContact(contact_id) {
  // Get one contact
  return get(CONTACTS_URL, authPayload()).then((res) => {
    return res.data.find(({ id }) => id === contact_id);
  });
}
export function fetchDocCategories() {
  // Get all doc_categories for select
  return get(DOC_CATEGORIES_URL, authPayload()).then((res) => {
    return res.data;
  });
}
export function fetchDocCategory(doc_category_id) {
  // Get all doc_categories for select
  return get(DOC_CATEGORIES_URL, authPayload()).then((res) => {
    return res.data.find(({ id }) => id === doc_category_id);
  });
}
export function fetchDocuments() {
  // Get all documents
  return get(DOCUMENTS_URL, authPayload()).then((res) => {
    return res.data;
  });
}
export function fetchDocument(doc_id) {
  // Get document
  return get(DOCUMENTS_URL, authPayload()).then((res) => {
    return res.data.find(({ id }) => id === doc_id);
  });
}
export function fetchIndustries() {
  // Get all industries for select
  return get(INDUSTRIES_URL, authPayload()).then((res) => {
    return res.data;
  });
}

export function fetchIndustry(industry_id) {
  // Get one industry
  return get(INDUSTRIES_URL, authPayload()).then((res) => {
    return res.data.find(({ id }) => id === industry_id);
  });
}
export function fetchProjects() {
  // Get all projects
  return get(PROJECTS_URL, authPayload()).then((res) => {
    console.log("res.data: ", res.data);
    return res.data;
  });
}
export function fetchProject(project_id) {
  // Get one project
  return get(PROJECTS_URL, authPayload()).then((res) => {
    console.log("res.data: ", res.data);
    return res.data.find(({ id }) => id === project_id);
  });
}
export function fetchSubIndustries() {
  // Get all sub_industries for select
  return get(SUB_INDUSTRIES_URL, authPayload()).then((res) => {
    return res.data;
  });
}
export function fetchSubIndustry(sub_industry_id) {
  // Get one subIndustry
  return get(SUB_INDUSTRIES_URL, authPayload()).then((res) => {
    return res.data.find(({ id }) => id === sub_industry_id);
  });
}
export function fetchTags() {
  // Get all tags for select
  return get(PROJECTS_TAGS_URL, authPayload()).then((res) => {
    return res.data;
  });
}
export function fetchTypes() {
  // Get all types for select
  return get(TYPES_URL, authPayload()).then((res) => {
    return res.data;
  });
}
export function fetchType(type_id) {
  // Get one type
  return get(TYPES_URL, authPayload()).then((res) => {
    return res.data.find(({ id }) => id === type_id);
  });
}
export function fetchSearch(
  id_option,
  id_category,
  id_industry,
  id_client,
  tags,
  project_code,
  id_sub_industry,
  id_type,
  id_client_industry
) {
  return get(
    searchUrl([
      id_option,
      id_category,
      id_industry,
      id_client,
      tags,
      project_code,
      id_sub_industry,
      id_type,
      id_client_industry,
    ]),
    authPayload()
  ).then((res) => {
    return res.data;
  });
}
export function fetchSubTypes() {
  // Get all sub_types for select
  return get(SUB_TYPES_URL, authPayload()).then((res) => {
    return res.data;
  });
}
export function fetchSubType(sub_type_id) {
  // Get all sub_types for select
  return get(SUB_TYPES_URL, authPayload()).then((res) => {
    return res.data.find(({ id }) => id === sub_type_id);
  });
}

//  /Get functions KM-------------------------------------------

// POST functions KM
// -------------------------------------------------------------
export function fetchPostCategory(values) {
  // Post new category
  return post(POST_CATEGORY_URL, values, authPayload()).then((res) => {});
}
export function fetchPostClient(values) {
  // Post new client
  return post(POST_CLIENT_URL, values, authPayload()).then((res) => {});
}
export function fetchPostContact(values) {
  // Post new contact
  return post(CONTACTS_URL, values, authPayload()).then((res) => {});
}
export function fetchPostIndustry(values) {
  // Post new industry
  return post(POST_INDUSTRY_URL, values, authPayload()).then((res) => {});
  // return `${POST_INDUSTRY_URL}.json`
}
export function fetchPostSubIndustry(values) {
  // Post new sub_industry
  return post(POST_SUB_INDUSTRY_URL, values, authPayload()).then((res) => {});
}
export function fetchPostDocument(values) {
  // Post new document
  return post(POST_DOCUMENT_URL, values, authPayload()).then((res) => {});
}
export function fetchPostProject(values) {
  // Post new project
  return post(POST_PROJECT_URL, values, authPayload());
}
export function fetchPostTags(values) {
  // post new tag
  return post(POST_TAGS_URL, values, authPayload()).then((res) => {});
}
export function fetchPostType(values) {
  // Post new type
  return post(POST_TYPE_URL, values, authPayload()).then((res) => {});
}
export function fetchPostSubType(values) {
  // Post new subType
  return post(POST_SUB_TYPE_URL, values, authPayload()).then((res) => {});
}

//  /POST functions KM-------------------------------------------

// DELETE functions KM
// se comenta pq no se puede eliminar un client ni un project pero no se quiere perder codigo
// -------------------------------------------------------------
// export function fetchDeleteClient(values) {
//   // Delete client
//   return del(`${DELET_CLIENT_URL}/${values.id}`, authPayload()).then((res) => {});
// }
export function fetchDeleteContact(values) {
  // Delete client
  return del(`${CONTACTS_URL}/${values.id}`, authPayload()).then((res) => {});
}
export function fetchDeleteDocument(values) {
  // Delete client
  return del(`${DELETE_DOCUMENT_URL}/${values.id}`, authPayload()).then(
    (res) => {}
  );
}
// export function fetchDeleteProject(values) {
//   // Delete client
//   return del(`${DELETE_PROJECT_URL}/${values.id}`, authPayload()).then((res) => {});
// }
export function fetchDeleteTag(values) {
  // Delete tag
  return del(`${DELETE_TAG_URL}/${values.id}`, authPayload()).then((res) => {});
}

//  /DELETE functions KM-------------------------------------------

// PATCH    functions KM
// ------------------------------------------------------------

export function fetchPatchClient(values) {
  // Patch (update) old client
  return patch(`${PATCH_CLIENT_URL}/${values.id}`, values, authPayload()).then(
    (res) => {}
  );
}
export function fetchPatchDocument(values) {
  // Patch (update) old client
  return patch(
    `${PATCH_DOCUMENT_URL}/${values.id}`,
    values,
    authPayload()
  ).then((res) => {});
}
export function fetchPatchProject(values) {
  // Patch (update) old client
  let id = values.id;
  delete values.id;

  console.log("Patch values: ", values);
  return patch(`${PATCH_PROJECT_URL}/${id}`, values, authPayload()).then(
    (res) => {}
  );
}

export function fetchPatchCheckListInicio(project_id, etapa, hito, tipo, boolValue) {
  // Patch (update) old client
  console.log("Comienzo de edición con parámetros: ", project_id, etapa, hito, tipo, boolValue);
  return patch(`${PATCH_CHECKLIST_INICIO_URL}`, {
    project_id: project_id,
    etapa: etapa,
    hito: hito,
    tipo: tipo,
    bool: boolValue
  }, authPayload())
  .then((res) => {
    console.log("res.data: ", res.data);
  })
  .catch((error) => {
    console.log("error: ", error);
  });
}

export function fetchPatchCheckListCierre(project_id, etapa, hito, tipo, boolValue) {
  // Patch (update) old client
  console.log("Comienzo de edición con parámetros: ", project_id, etapa, hito, tipo, boolValue);
  return patch(`${PATCH_CHECKLIST_CIERRE_URL}`, {
    project_id: project_id,
    etapa: etapa,
    hito: hito,
    tipo: tipo,
    bool: boolValue
  }, authPayload())
  .then((res) => {
    console.log("res.data: ", res.data);
  })
  .catch((error) => {
    console.log("error: ", error);
  });
}



//  /PATCH  functions KM----------------------------------------------

export function consultantUrl(consultantId) {
  return `${api_url}consultants/${consultantId}.json`;
}

export function consultantPutUrl(consultantId) {
  return `${api_url}consultants/${consultantId}`;
}

export function firstLevelIndustryUrl(firstLevelIndustryId) {
  return `${api_url}first_level_industries/${firstLevelIndustryId}.json`;
}

export function firstLevelIndustryPutUrl(firstLevelIndustryId) {
  return `${api_url}first_level_industries/${firstLevelIndustryId}`;
}

export function secondLevelIndustryUrl(secondLevelIndustryId) {
  return `${api_url}second_level_industries/${secondLevelIndustryId}.json`;
}

export function secondLevelIndustryPutUrl(secondLevelIndustryId) {
  return `${api_url}second_level_industries/${secondLevelIndustryId}`;
}

export function firstProjectTypeUrl(firstProjectTypeId) {
  return `${api_url}first_project_types/${firstProjectTypeId}.json`;
}

export function firstProjectTypePutUrl(firstProjectTypeId) {
  return `${api_url}first_project_types/${firstProjectTypeId}`;
}

export function secondProjectTypeUrl(secondProjectTypeId) {
  return `${api_url}second_project_types/${secondProjectTypeId}.json`;
}

export function secondProjectTypePutUrl(secondProjectTypeId) {
  return `${api_url}second_project_types/${secondProjectTypeId}`;
}

export function clientUrl(clientId) {
  return `${api_url}clients/${clientId}.json`;
}

export function clientPutUrl(clientId) {
  return `${api_url}clients/${clientId}`;
}

export function projectUrl(projectId) {
  return `${api_url}projects/${projectId}.json`;
}

export function projectPutUrl(projectId) {
  return `${api_url}projects/${projectId}`;
}

export function proposalPutUrl(proposalId) {
  return `${api_url}proposals/${proposalId}`;
}

export function projectAsignationUrl(projectAsignationId) {
  return `${api_url}project_asignations/${projectAsignationId}.json`;
}

export function projectAsignationPutUrl(projectAsignationId) {
  return `${api_url}project_asignations/${projectAsignationId}`;
}

export function proposalAsignationPutUrl(proposalAsignationId) {
  return `${api_url}proposal_asignations/${proposalAsignationId}`;
}

export function vacationUrl(vacationId) {
  return `${api_url}vacations/${vacationId}.json`;
}

export function vacationPutUrl(vacationId) {
  return `${api_url}vacations/${vacationId}`;
}

export function contactUrl(contactId) {
  return `${api_url}contacts/${contactId}.json`;
}

export function contactPutUrl(contactId) {
  return `${api_url}contacts/${contactId}`;
}

export function documentUrl(documentId) {
  return `${api_url}documents/${documentId}.json`;
}

export function documentPutUrl(documentId) {
  return `${api_url}documents/${documentId}`;
}

export function informationSourceUrl(informationSourceId) {
  return `${api_url}information_sources/${informationSourceId}.json`;
}

export function informationSourcePutUrl(informationSourceId) {
  return `${api_url}information_sources/${informationSourceId}`;
}

export function apqcToolPutUrl(apqcToolId) {
  return `${api_url}apqc_tools/${apqcToolId}`;
}

export function singleDocumentUrl(singleDocumentId) {
  return `${api_url}single_documents/${singleDocumentId}.json`;
}

export function singleDocumentPutUrl(singleDocumentId) {
  return `${api_url}single_documents/${singleDocumentId}`;
}

export function userChangeSessionUrl(userId) {
  return `${api_url}users/change_session/${userId}`;
}

export function userPutUrl(userId) {
  return `${api_url}users/${userId}`;
}
