import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getNotifications,
  deleteNotification,
  markNotificationAsRead,
  markNotificationAsUnread,
  markAllAsRead,
  destroyAllNotifications,
} from "../../constants/apiURLs";
import {
  Box,
  Paper,
  List,
  ListItem,
  Divider,
  ListItemText,
  IconButton,
  Typography,
  Popper,
  ClickAwayListener,
  Badge,
  Tooltip,
} from "@mui/material";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import consumer from "../../constants/cableURL";
import { authPayload } from "../../utils/requestsPayloads";

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    const {
      headers: { Authorization },
    } = authPayload();
    const token = Authorization.split(" ")[1];
    const subscription = consumer.subscriptions.create(
      { channel: "NotificationChannel", token: token },
      {
        received(data) {
          console.log(data.message);
          queryClient.invalidateQueries("notifications");
        },
      }
    );

    return () => {
      consumer.subscriptions.remove(subscription);
    };
  }, [queryClient]);

  const { data: notifications, status: notificationsStatus } = useQuery({
    queryKey: ["notifications"],
    queryFn: getNotifications,
    select: (data) => data.sort((a, b) => b.id - a.id),
  });

  const deleteMutation = useMutation({
    mutationFn: deleteNotification,
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });

  const markMutation = useMutation({
    mutationFn: markNotificationAsRead,
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });

  const unmarkMutation = useMutation({
    mutationFn: markNotificationAsUnread,
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });

  const destroyAllMutation = useMutation({
    mutationFn: destroyAllNotifications,
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });

  const markAllAsReadMutation = useMutation({
    mutationFn: markAllAsRead,
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notificaciones" : undefined;

  if (notificationsStatus === "loading") {
    return <div />;
  }

  const unread = notifications ? notifications.filter((notification) => !notification.read).length : 0;

  const handleNotificationClick = (notification) => {
    const projectId = notification.custom_data?.project_id;
    if (
      (notification.type === "ClosingProjectNotification" ||
       notification.type === "ApprovedNotificationCloseProject" ||
       notification.type === "DeniedNotificationCloseProject") &&
      projectId
    ) {
      window.location.href = `/modulo_km/project_view/${projectId}`;
    }
     else if (
      notification.type === "EditRequestApprovalNotification" ||
      notification.type === "RequestApprovalNotification" ||
      notification.type === "DeniedNotification" ||
      notification.type === "ApprovedNotification"
    ) {
      window.location.href = "/performance/requests";
    } else {
      console.error("Unhandled notification type", notification.type);
    }
  };

  return (
    <Box>
      <IconButton aria-describedby={id} onClick={handleClick} sx={{ color: "white" }}>
        <Badge
          color="highlight"
          variant="dot"
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          invisible={unread === 0}
        >
          <MarkunreadMailboxOutlinedIcon />
        </Badge>
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={24} sx={{ pl: 2, pr: 2, borderRadius: 4, mt: 2 }}>
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", pt: 1 }}
            >
              <Typography variant="h6">Notificaciones</Typography>
              <Box>
                <Tooltip title="Marcar todas como leídas">
                  <IconButton onClick={() => markAllAsReadMutation.mutate()} color="primary" sx={{ mr: -1 }}>
                    <MarkEmailReadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar todas">
                  <IconButton onClick={() => destroyAllMutation.mutate()} sx={{ mr: -1, color: "#500" }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Divider sx={{ mr: -2, ml: -2 }} />
            <List dense sx={{ width: 360, maxHeight: 600, overflowY: "auto" }}>
              {notifications.map((notification) => (
                <React.Fragment key={notification.id}>
                  <ListItem button onClick={() => handleNotificationClick(notification)}>
                    <ListItemText
                      primary={
                        <Typography variant="body1" sx={{ opacity: notification.read ? 0.7 : 1 }}>
                          {notification.title}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          align="justify"
                          variant="body2"
                          sx={{ opacity: notification.read ? 0.4 : 1 }}
                        >
                          {notification.body}
                        </Typography>
                      }
                    />
                    <Box>
                      {!notification.read ? (
                        <Tooltip title="Marcar como leída">
                          <IconButton
                            edge="end"
                            aria-label="mark_as_read"
                            onClick={(e) => {
                              e.stopPropagation();
                              markMutation.mutate(notification.id);
                            }}
                            color="primary"
                            sx={{ ml: 1 }}
                          >
                            <MarkEmailReadIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Marcar como no leída">
                          <IconButton
                            edge="end"
                            aria-label="mark_as_unread_read"
                            onClick={(e) => {
                              e.stopPropagation();
                              unmarkMutation.mutate(notification.id);
                            }}
                            sx={{ ml: 1, color: "#AAA" }}
                          >
                            <MarkEmailReadIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {/* <Tooltip title="Eliminar">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteMutation.mutate(notification.id);
                          }}
                          sx={{ ml: 1, color: "#500" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip> */}
                    </Box>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default Notifications;
